<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <div class="tableList">
      <!-- 封装表单table组件 -->

      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="disable" slot-scope="{ row }">
          <el-tag
            v-if="row.disable === 1"
            type="danger"
            effect="dark"
            size="mini"
          >
            已注销
          </el-tag>
          <el-tag
            class="theTagGreen"
            v-else
            type="success"
            effect="dark"
            size="mini"
          >
            正常
          </el-tag>
        </template>
        <template slot="imgHead" slot-scope="{ row }">
          <el-image
            v-if="row.imgHead"
            style="width: 50px; height: 50px"
            :src="row.imgHead"
            :preview-src-list="[row.imgHead]"
          >
          </el-image>
          <span v-else>暂无</span>
        </template>
        <!-- <template slot="state" slot-scope="scope">
           <FillingStatus :value="scope.row" />
        </template>
        <template slot="fillRate" slot-scope="scope">
          <Percentage :value="scope.row"></Percentage>
        </template> -->
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleDisable(row)"
              primary
              >{{ row.disable === 1 ? "启用" : "注销" }}</el-button
            >
            <el-button
              class="deleteBtn"
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
    </div>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import { selectUser, deleteUser, disableUser } from "@/services/mall/user";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
  },
  async created() {
    this.getListData();
  },
  data() {
    return {
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
  },
  watch: {},
  methods: {
    async getListData(listQuery) {
      this.loading = true;
      const { data, code, count } = await selectUser(
        listQuery || this.listQuery
      );
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },
    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },
    /* 注销 */
    handleDisable(row) {
      this.$confirm(
        `确定${row.disable === 1 ? "启用" : "注销"}手机号为"${
          row.phone
        }"的用户吗?`,
        "注销",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then((res) => {
        disableUser({ id: row.id, disable: row.disable === 1 ? 0 : 1 }).then(
          (res) => {
            if (res.code === 200) {
              this.getListData();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          }
        );
      });
    },
    /* 删除 */
    handleDelete(row) {
      this.$confirm(`确定删除手机号为"${row.phone}"的用户吗?`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        deleteUser({ id: row.id }).then((res) => {
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}


</style>
