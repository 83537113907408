export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    { label: "账号状态", prop: "disable", slots: "disable", width: 200 },
    { label: "openid", prop: "openid", width: 300 },
    { label: "头像", prop: "imgHead", slots: "imgHead", width: 200 },
    { label: "昵称", prop: "userName", width: 200 },
    { label: "手机号", prop: "phone", width: 200 },
    {
      label: "登录时间",
      prop: "create_time",
      width: 200
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 200,
      slots: "operation",
    },
  ];
}
